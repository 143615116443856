import React from "react"

import Layout from "../components/layout/layout.js"
import SEO from "../components/seo/seo"


const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <h1>This is where my projects live</h1>
  </Layout>
)

export default ProjectsPage
